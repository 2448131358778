import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourses } from "../store/courseSlice";
import { toast } from "react-toastify";
import CourseCard from "../components/CourseCard";
import { useParams } from "react-router-dom";
import { saverequest } from "../store/requestSlice";
import Loading from "../components/Loading";
import CourseForm from "../components/CourseForm";
import SimpleReactValidator from "simple-react-validator";

function Affiliatemarketing() {
  const params = useParams();
  const markterId = params.id;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const allcourses = useSelector((state) => state.courseSlice.allcourses);
  const [inputs, setInputs] = useState({
    arName: "",
    enName: "",
    phone1: "",
    phone2: "",
    education: "",
    country: "",
    city: "",
    courseId: "",
    orderStatusId: "4eb73a8b-1cbf-4829-f25c-08dbc710612b",
    markterId: markterId,
    isCalled: false,
    qes: "",
  });

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  useEffect(() => {
    document.title = "Home";
    dispatch(getAllCourses())
      .unwrap()
      .then((res) => {
        // console.log(res)
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.title);
        console.log(err.response);
      });
  }, [dispatch]);

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(inputs);

    if (simpleValidator.current.allValid()) {
      dispatch(saverequest(inputs))
        .unwrap()
        .then((res) => {
          toast.success(
            "تم التسجيل بالنجاح وسيتم توجيهك لصفحة الفيسبوك الخاصه بنا"
          );
          setTimeout(() => {
            window.location.href = "https://www.facebook.com/ELQARIAALZAKIA";
            setLoading(false);
          }, 2000);
          // setInputs((prevInputs) => ({
          //   ...prevInputs,
          //   arName: "",
          //   enName: "",
          //   phone1: "",
          //   phone2: "",
          //   education: "",
          //   country: "",
          //   city: "",
          //   courseId: "",
          //   qes: "",
          // }));
        })
        .catch((err) => {
          toast.error(err.response.data.title);
          setLoading(false);
        });
    } else {
      toast.error(`من فضلك اكمل البيانات`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="affiliatemarketing-page">
        <div className="container">
          <div className="sectoin_header">
            <h5>جميع الكورسات</h5>
          </div>
          <div className="row">
            {allcourses.map((item) =>
              item.isActive ? (
                <CourseCard key={item.id} course={item} markterId={markterId} />
              ) : null
            )}
          </div>

          <div className="aff-form">
            <div className="sectoin_header">
              <h5>التسجيل</h5>
            </div>

            <CourseForm
              inputs={inputs}
              saveData={saveData}
              handelInputChange={handelInputChange}
              allcourses={allcourses}
              courseId=""
              simpleValidator={simpleValidator}
              setInputs={setInputs}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Affiliatemarketing;
